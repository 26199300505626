@import '~scss/variables.scss';

.templateEstimate {
	display: inherit;
}

.price {
	color: $color-blue-base;
}

.h5 {
	@include margin-medium-bottom;
}

.estimatedParts {
	display: flex;
	align-items: center;
	@include margin-small-bottom;
}

.doorIcon {
	@include padding-small-right;
}

.h6 {
	margin-top: 0;
}
