@use 'sass:math';

@mixin all-svg-elements {
	circle,
	ellipse,
	line,
	mesh,
	path,
	polygon,
	polyline,
	rect {
		@content;
	}
}

@function stroke-width($size) {
	$viewBoxSize: 2.4;
	$targetPixelSize: 0.15;
	@return $targetPixelSize * math.div($viewBoxSize, $size);
}

@mixin icon-size($icon-size) {
	width: #{$icon-size}rem;
	height: #{$icon-size}rem;
	min-width: #{$icon-size}rem;
	min-height: #{$icon-size}rem;
	@include all-svg-elements {
		stroke-width: #{stroke-width($icon-size)}rem;
	}
}
