@import '~scss/variables.scss';

@mixin on-button-control-hover {
	:global {
		.button:hover {
			:local {
				.control {
					@content;
				}
			}
		}
	}
}

.icon {
	@include all-svg-elements {
		stroke-width: 1.3;
	}
	width: 8rem;
	height: auto;
	@include margin-medium-bottom;
}
