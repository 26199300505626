@import '~scss/variables.scss';
@import '~components/StorybookComponents/scss/storybook-variables.scss';

.hasPaddingTop {
	@include padding-large-top;
}

.titleContainer {
	white-space: nowrap;
	.title {
		white-space: normal;
	}
	& > * {
		display: inline;
	}
}

.description {
	margin-top: $size-12;
}

.content {
	&.hasTitleOrDescription {
		@include padding-medium-top;
	}
}

.title {
	padding-top: 0;
}

.titleIcon {
	position: relative;
	top: 0.3rem;
}
