@import '~scss/variables.scss';

.controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: $size-16;
}

.centerContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: flex-end;
	justify-content: center;
}

.ourProducts {
	color: $color-red-base;
}

.largeBlueText {
	color: $color-blue-base;
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
	margin-top: $size-12;
}

.mediumText {
	font-size: medium;
}

.whiteBackground {
	background-color: $color-white;
}

.iconContainer {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: $size-32;
	li {
		list-style: none;
		margin-bottom: 7rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;
		width: 35%;
		@include mobile-up {
			width: 26%;
		}
	}
}

.iconLink {
	display: flex;
	flex-direction: column;
	align-items: center;

	span {
		font-family: $font-family-1dormakaba-bold;
		font-weight: $font-weight-bold;
	}
}
