@import '~scss/variables.scss';

.flexContainer {
	display: flex;
	align-items: center;
	text-transform: capitalize;
}

.flexWrapNone {
	flex-wrap: nowrap;
}
