@import '~scss/variables.scss';

.tabs {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	overflow-x: auto;
	&:after {
		content: '';
		border-bottom: 0.2rem solid $color-whale-blue;
		width: 100%;
	}
	li {
		flex-shrink: 0;
		list-style: none;
		a {
			@include padding-small;
			text-decoration: none;
			display: block;
			transition: all 0.3s ease;
			color: $color-gray-1;
			border-bottom: 0.2rem solid $color-whale-blue;
			font-weight: $font-weight-semiBold;
			&:hover {
				color: $color-blue-base;
			}
			&:global {
				&.active {
					border-bottom: 0.2rem solid $color-blue-base;
					color: $color-blue-base;
				}
			}
		}
	}
}

.scrollContainer {
	overflow-y: hidden;
}
