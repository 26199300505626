@import '~scss/variables.scss';

.headerSuperNav {
	width: 100%;
	background: $color-gray-7;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: row;
	padding-top: $size-2;
	padding-bottom: $size-2;
}

.logoLink {
	width: 220px;
	@include tablet-up {
		width: auto;
	}
}

.supportPhone,
.companyLink {
	color: $color-gray-1;
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
}

.supportPhone {
	font-weight: $font-weight-bold;
}

.companyLink {
	color: $color-blue-base;
	border-right: 0.1rem solid $color-gray-5;
	padding-left: $size-12;
	padding-right: $size-12;
	padding-top: $size-12;
	padding-bottom: $size-12;
	@include type-100;
	@include tablet-up {
		@include type-200;
	}
}

.header {
	width: calc(100% - $size-48);
	margin: 0 auto;
	display: flex;
	align-items: center;
	@include padding-medium-vertical;
	position: relative;
}

.headerMenu {
	position: absolute;
	right: 0;
	top: 96px;
	height: calc(100vh - 96px);
	background: $color-whale-blue;
	width: 100%;
	display: none;
	ul {
		margin: 0;
		padding: 0;
		li {
			list-style: none;
			padding: 0;
			a {
				font-weight: $font-weight-regular;
				@include padding-medium;
				@include padding-small-vertical;
				display: block;
			}
		}
	}
	@include tablet-up {
		top: 112px;
		height: calc(100vh - 112px);
	}
	@include desktop-up {
		display: block;
		position: static;
		top: 0;
		padding-left: 2rem;
		padding-right: 2rem;
		height: auto;
		ul {
			padding-top: 1rem;
			padding-bottom: 1rem;
			li {
				padding-left: $size-12;
				padding-right: $size-12;
				display: inline-block;
				vertical-align: middle;
				a {
					padding: 0;
				}
				&:first-child {
					padding-left: 0;
				}
			}
		}
	}
	&.showMenu {
		display: block;
	}
}

.headerNav {
	@include desktop-up {
		width: 100%;
		opacity: 1;
		visibility: visible;
		z-index: initial;
		position: relative;
		left: initial;
		top: initial;
		margin-left: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.headerContainer {
	position: relative;
	z-index: 2;
}

.headerNavRight {
	display: none;
	@include desktop-up {
		display: flex;
	}
}

.headerSearch {
	width: 40rem !important;
	padding-left: 0 !important;
}
.paddingRightImportant {
	right: 0 !important;
	padding-right: 0 !important;
}

.logo {
	line-height: 0;
	width: 16rem;
	@include desktop-up {
		width: 20rem;
	}
}

.menuToggle {
	padding: 1.2rem 0.6rem;
	height: $size-48;
	width: $size-48;
	z-index: $z-index-menu-toggle;
	margin-left: $size-2;
	cursor: pointer;
	display: block;
	border: none;
	background: none;
	margin-left: auto;
	@include desktop-up {
		display: none;
	}
	.target {
		height: 100%;
		position: relative;
	}
	&:hover span {
		background-color: $color-gray-5;
	}
	span {
		display: block;
		position: absolute;
		width: 100%;
		top: 50%;
		left: 0;
		transform-origin: 50% 50%;
		transition: all 0.3s ease;
		height: 0.1rem;
		border-radius: 0.4rem;
		background-color: $color-gray-3;
		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(3) {
			top: 100%;
		}
	}
}

.current {
	text-decoration: underline;
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
}

.headerLinksParagraph {
	margin: 0px;
	text-align: center;
}

// Print Overrides for Individual Quote Screen
.hideFromPrint {
	@media print {
		display: none !important;
	}
}
