@import '~scss/variables.scss';

.table {
	position: relative;
}

.tableWrapper {
	position: relative;
}

.tableDom {
	border: none;
	border-spacing: 0;
	width: 100%;
	@include tablet-up {
		table-layout: fixed;
	}
}

.tableOverlay {
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.8);
	transition: 0.3s opacity;

	&.displayLoadingOverlay {
		pointer-events: all;
		opacity: 1;
		transition: 0.3s opacity;
	}
}

.tr {
	@include tablet-up {
		&:first-child {
			.th:first-child {
				border-top-left-radius: $border-radius;
			}
			.th:last-child {
				border-top-right-radius: $border-radius;
			}
		}
	}
	// .td {
	// background: $color-gray-6;
	// .notification & {
	// 	@include tablet-down {
	// 		background: transparent;
	// 	}
	// }
	// }
	// &:nth-of-type(odd) {
	// 	.td {
	// 		background: $color-gray-7;
	// 		.notification & {
	// 			@include tablet-down {
	// 				background: transparent;
	// 			}
	// 		}
	// 	}
	// }
}

.td,
.th {
	padding: 1rem 1.2rem;
	@include tablet-up {
		padding: 1.4rem 2rem;
	}
	overflow: hidden;
	text-overflow: ellipsis;

	border-bottom: 0.1rem solid $color-blue-light;
	text-align: left;
}

.tableBody {
	.tr:last-child {
		.td {
			border-color: transparent;
		}
	}
}

.td {
	vertical-align: top;
}

.th {
	font-family: $font-family-1dormakaba-semiBold;
	font-weight: $font-weight-semiBold;
}

.headerItemInner {
	display: flex;
	align-items: center;
	text-transform: capitalize;
	@include type-100;
	height: 100%;
	text-align: left;
}

button.headerItemInner {
	cursor: pointer;
}

.headerLabel {
	margin-top: 0.2rem;
	color: $color-gray-2;
	font-weight: $font-weight-semiBold;
	font-family: $font-family-1dormakaba-semiBold;
}

.viewAllLink {
	width: 100%;
	text-align: center;
	border-radius: 0 0 $border-radius $border-radius;
}

.header {
	background-color: $color-whale-blue;
	.data & {
		background-color: $color-whale-blue;
		// background-color: $color-gray-6;
		// @include tablet-up {
		// 	background-color: transparent;
		// }
	}
	padding: 2rem 2rem 2rem 2rem;
}

.title {
	width: 100%;
	@include type-block-title;
}

.footer {
	@include margin-small-top;
}

.link {
	border-radius: 0;
	width: 100%;
	padding-top: 1.85rem;
	span {
		justify-content: center;
	}
	&:before,
	&:after {
		border-radius: 0;
		@include tablet-up {
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}
	@include tablet-up {
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}
}

.sortIcon {
	margin-left: $size-8;
}

.tableScroller {
	@include tablet-down {
		overflow-x: scroll;
		overflow-y: visible;
		width: 100%;
	}
}

.tableScroller {
	@include tablet-down {
		margin-left: 50%;
		overflow-x: scroll;
		overflow-y: visible;
		width: 50%;
		z-index: 0;
	}
}

@mixin static-column-properties {
	left: 0;
	position: absolute;
	top: auto;
	width: 50%;
	z-index: 3;
	border-right: 1px solid #b2b9bd;
	// display: flex;
	// align-items: center;
	&:after {
		content: '';
		box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
		height: 100%;
		top: 0;
		right: -2px;
		left: initial;
		position: absolute;
		opacity: 0.4;
		width: 4px;
		z-index: 2;
		background: none;
	}
}

.td,
.th {
	@include tablet-down {
		&:first-of-type {
			min-width: 50%;
			@include static-column-properties;
		}
	}
}

.isFirstColumnHiddenOnMobile {
	.td,
	.th {
		@include tablet-down {
			&:first-of-type {
				display: none;
			}
			&:nth-of-type(2) {
				@include static-column-properties;
			}
		}
	}
}
.isSingleColumnOnMobile {
	@include tablet-down {
		.tableScroller {
			width: 100%;
			margin-left: 0;
		}
	}
}

.screenReaderTextMobile {
	@include tablet-down {
		@include screen-reader-text;
	}
}

.columnSelect {
	width: 5.6rem;
	@include tablet-down {
		display: none;
	}
}

.mainLinkFocusRing.mainLinkFocusRing.mainLinkFocusRing.mainLinkFocusRing {
	border-radius: 0;
	border-bottom-left-radius: 0.85rem;
	border-bottom-right-radius: 0.85rem;
}

.message {
	background-color: $color-gray-7;
	@include padding-small;
}
