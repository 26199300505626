@import '~scss/variables.scss';

.progressBar {
	height: 1rem;
	background-color: $color-gray-3;
}

.progressAmount {
	background: $color-blue-base;
	height: 100%;
}
