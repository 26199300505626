@import '~scss/variables.scss';

.pageFrame {
	padding-top: $size-40;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	min-height: 100%;
	background: $color-gray-7;
}

.pageFrameContent {
	flex: 1 1;
}

.addFlexSpace {
	display: flex;
	.content {
		height: auto;
	}
}

.pageFrameTop {
	padding-bottom: 0;
	@include tablet-up {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.pageFrameTopInner {
	min-width: 0;
}

.pageFrameTitle {
	margin-top: 0;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.backLocation {
	display: inline-block;
	@include margin-small-top;
}

.backLocationIcon {
	margin-right: 0.5rem;
}

.contentTopRight {
	@include margin-medium-top;
	@include tablet-up {
		margin-top: -0.8rem;
		margin-bottom: -0.8rem;
	}
}

// TODO: Might need to move some vertical spacing back depending on how the design works out. For now removing it. Clean this comment up if found near end of build
// .content {
// 	@include padding-medium-vertical;
// }

.content {
	height: 100%;
}

.breadcrumbs {
	margin-bottom: 1.2rem;
}

.isContentManagedTitle {
	padding-top: 0;
	.content {
		padding: 0;
	}
}
