@import '~scss/variables.scss';

.uploadDropZone {
	padding: $size-56 $size-16;
	border: $size-2 dashed $color-blue-light;
	background-color: $color-gray-7;
	color: $color-gray-1;

	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.8rem;

	&.success,
	&.active {
		background-color: $color-gray-3;
	}
}
