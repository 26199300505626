@import '~scss/variables.scss';

.input {
	position: absolute !important;
	height: 0.1rem;
	width: 0.1rem;
	overflow: hidden;
	transform: translateY(1.2rem);
	clip: rect(0.1rem 0.1rem 0.1rem 0.1rem); /* IE6, IE7 */
	clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
	cursor: pointer;
}

.label {
	position: relative;
	display: inline-block;

	padding-left: $size-32;
	transform: translateY(-1px);
	padding-top: $size-4;
	padding-bottom: $size-4;
	cursor: pointer;
}

.label::before,
.label::after {
	position: absolute;
	content: '';

	/*Needed for the line-height to take effect*/
	display: inline-block;
}

/*Outer box of the fake checkbox*/
.label::before {
	height: 2.4rem;
	width: 2.4rem;
	border: 0.1rem solid $color-gray-4;
	left: 0;
	top: 0.3rem;
	background-repeat: no-repeat;
	background-position: center;
    background: $color-white;
}

.small {
	@include type-080;
	label {
		padding-left: 2.4rem;
	}
	.label::before {
		width: 1.8rem;
		height: 1.8rem;
	}
	.input:checked + .label::before {
		background-size: 1rem;
	}
}
.isLabelHidden::before {
	top: -0.8rem;
}

/*Unhide on the checked state*/
.input:checked + .label::before {
	background-image: url('~assets/svg/cancel.svg');
	background-position: center;
	background-repeat: no-repeat;
}

/*Adding focus styles on the outer-box of the fake checkbox*/

:global {
	.user-is-tabbing {
		:local {
			.input:focus + .label::before {
				outline: $color-blue-light auto 0.4rem;
			}
		}
	}
}

/*Adding style for disabled checkbox*/
.input:disabled + .label {
	color: $color-gray-6;
}

.input:disabled + .label::before {
	background: $color-gray-6;
	border: 0.1rem solid $color-gray-6;
}

.error {
	color: $color-red-base;
}

.label {
	user-select: none;
}
