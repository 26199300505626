@import '~scss/variables.scss';

.labelClass {
	@include type-300;
	text-transform: none;
	color: $color-gray-1;
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
}

.dynamicQuestionInputs {
	margin-bottom: $size-32;
}
