@import '~scss/variables.scss';

.inputSearch {
	border: none !important;
	border-bottom: 0.1rem solid $color-blue-base !important;
}

.inputSearchIcon {
	right: 0;
}
