@import '~scss/variables.scss';

.tabContentHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: $size-16;
	padding: $size-16 0;
}

.helperTopText {
	align-items: center;
	justify-content: space-between;
	padding-left: 3rem;
	padding-right: 3rem;
	background: $color-whale-blue;
	@include padding-medium-vertical;
	@include margin-medium-vertical;
	@include type-200;
	position: relative;
	margin: 20 0 0 0 auto;
}

.boldText {
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
}

.marginTopZero {
	margin-top: 0;
}

.marginBottomZero {
	margin-bottom: 0;
}

.uppercaseText {
	text-transform: uppercase;
}

.paddingLeft {
	padding-left: 3rem;
}

.italicText {
	font-style: italic;
}
