@import '~scss/variables.scss';

.notificationContentTop {
	@include tablet-down {
		display: none;
	}
}

.scrollReference {
	visibility: hidden;
	position: relative;

	bottom: 0rem;
	@include tablet-up {
		bottom: 4rem;
	}
}

.topTitle {
	width: 100%;
	margin-bottom: 1rem;
	@include type-block-title;
}

.marginBottom {
	margin-bottom: 10px;
}

.searchInputField {
	width: 42rem;
	@include desktop-down {
		width: 100%;
	}
}
