@mixin all-button-types {
	.primary,
	.secondary,
	.danger,
	.ghost,
	.control,
	.darkOrange {
		@content;
	}
}

@mixin button {
	:global {
		.user-is-tabbing {
			:local {
				@include all-button-types {
					&:focus {
						.focusRing {
							position: absolute;
							top: -0.4rem;
							right: -0.4rem;
							bottom: -0.4rem;
							left: -0.4rem;
							border: 0.2rem solid $color-blue-base;
							border-radius: 3.2rem;
							&.black {
								border-color: $color-gray-1;
							}
							&.white {
								border-color: $color-white;
							}
							&.blue {
								border-color: $color-blue-base;
							}
							&.red {
								border-color: $color-red-base;
							}
							&.green {
								border-color: $color-green-base;
							}
							&.gray {
								border-color: $color-gray-4;
							}
						}
						outline: 0;
					}
				}
			}
		}
	}

	@include all-button-types {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		border: none;
		color: $color-white;
		transition: all 0.3s ease;
		font-size: 1.6rem;
		line-height: 1.6rem;
		transform: translateY(0);
		text-transform: capitalize;
		margin: 0;
		box-shadow: none;
		font-weight: $font-weight-bold;
		font-family: $font-family-1dormakaba-bold;
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
		* {
			pointer-events: none;
		}
		&:disabled,
		&:disabled:hover {
			cursor: initial;
			background-color: $color-gray-6;
			color: $color-gray-4;
		}
		&[aria-busy='true'],
		&[aria-busy='true']:hover {
			cursor: initial;
		}
		width: 100%;
		text-align: center;
		@include tablet-up {
			width: auto;
		}
	}

	.medium {
		padding: 1.95rem $size-40 1.85rem $size-40;
	}

	.small {
		padding-left: $size-12;
		padding-right: $size-12;
		padding-top: $size-12;
		padding-bottom: $size-12;
	}

	.smallest {
		padding: 0;
	}

	.primary {
		background-color: $color-blue-base;
		color: $color-white;
		&:hover {
			background-color: $color-blue-dark;
		}
		&:active {
			background-color: $color-blue-darker;
		}
		&[aria-busy='true'],
		&[aria-busy='true']:hover {
			background-color: $color-blue-base;
		}
	}

	.secondary {
		background-color: $color-gray-3;
		color: $color-black;
		&:hover {
			background-color: $color-black;
			color: $color-white;
		}
		&:active {
			background-color: $color-gray-2;
			color: $color-white;
		}
		&[aria-busy='true'],
		&[aria-busy='true']:hover {
			background-color: $color-gray-3;
			color: $color-black;
		}
	}

	.danger {
		background-color: $color-red-base;
		&:hover {
			background-color: $color-red-dark;
		}
		&:active {
			background-color: $color-red-darker;
		}
		&[aria-busy='true'],
		&[aria-busy='true']:hover {
			background-color: $color-red-base;
		}
	}

	.ghost {
		background-color: inherit;
		color: $color-blue-base;
		box-shadow: none;
		&:hover {
			background-color: $color-whale-blue;
		}
		&:active {
			background-color: $color-blue-base;
			color: $color-white;
		}
	}

	.control {
		background-color: transparent;
		color: $color-blue-base;
		box-shadow: none;
		width: auto;
		&:disabled,
		&:disabled:hover {
			color: $color-gray-4;
		}
	}

	.link {
		padding: 0;
		@include link;
	}

	.linkSmall {
		padding: 0;
		@include link;
		font-size: 1rem;
		line-height: $size-12;
		transform: translateY(-2px);
	}

	.buttonContent {
		z-index: $z-index-button-content;
		display: flex;
		align-items: center;
		position: relative;
		pointer-events: none;
		justify-content: center;
	}
}
