@import '~scss/variables.scss';

.productList {
	display: inherit;
}

.productListHeader {
	border: 0.1rem solid $color-gray-5;
	@include padding-medium;
	background: $color-white;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 0;
	}
	margin-bottom: -0.1rem;
}

.products {
	background: $color-white;
}

.productListFooter {
	display: flex;
	justify-content: flex-end;
	@include padding-large-vertical;
}

.productCardBorderOffset {
	margin-bottom: -0.1rem;
}

.noProductsFound {
	margin-top: -0.1rem;
	border: 0.1rem solid $color-gray-5;
	@include padding-medium;
	background: $color-white;
}
