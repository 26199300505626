@import '~scss/variables.scss';

.createOpeningViewPageFrame {
	display: flex;
	flex-direction: column;
	flex: 1 auto;
	height: 100%;
	position: relative;
}

.createOpeningViewPageFrameContent {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.createOpeningNavigation {
	width: 100%;
	position: sticky;
	bottom: 0;
	left: 0;
}
