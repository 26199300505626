@import '~scss/variables.scss';

.productSingleView {
	h1,
	h2 {
		margin-top: 0;
	}
}

.productImageContainer {
	width: 100%;
	min-height: 50rem;
	position: relative;
	border: 1px solid $color-gray-5;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 24rem;
	@include tablet-up {
		min-height: 50rem;
	}
}

.productImage {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.imageComingSoon {
	border: 0.1rem solid $color-gray-5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.productDetails {
	@include tablet-up {
		@include padding-small-left;
	}
	@include desktop-up {
		@include padding-large-left;
	}
}

.productDiscount {
	h3 {
		margin-top: $size-16;
		small {
			@include type-200;
		}
	}
	p {
		@include type-100;
		margin-top: 0;
		span {
			color: $color-green-base;
		}
	}
}

.productContent {
	@include margin-medium-bottom;
	@include margin-small-top;
}

.ecomControls {
	.button {
		@include margin-small-top;
	}
}

.selectors {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.ecomInput {
	@include margin-small-right;
}

.qty {
	width: 10rem;
	margin-bottom: 0;
}

.width {
	width: 12rem;
	margin-bottom: 0;
}

.quoteSelector {
	border: 0.1rem solid $color-gray-5;
	padding: 0;
	margin: 0;
	max-height: 18rem;
	overflow-y: scroll;
	li {
		list-style: none;
	}
}

.quoteSelectorButton {
	width: 100%;
	&:hover {
		.quoteSelectorButtonContent {
			background: $color-whale-blue;
		}
	}
}

.quoteSelectorButtonContent {
	padding: $size-8 $size-24;
	justify-content: flex-start;
}
