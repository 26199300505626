@import '~scss/variables';

.pagination {
	display: flex;
	align-items: center;
	// width: min-content;
	@include tablet-up {
		background: none;
	}
}

.paginationFormContainer {
	display: flex;
	align-items: center;
	@include padding-small-horizontal;
	input {
		width: 10rem;
		@include margin-small-right;
	}
}

.inputFrame {
	margin: 0;
}

.paginationForm {
	width: auto;
}

.link {
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
	display: flex;
	align-items: center;
}

.paginationSubmitButton {
	display: none;
}
