@import '~scss/variables.scss';

.quoteEditName {
	display: inherit;
	@include margin-small-bottom;
	margin-top: 3.2rem;
	h1 {
		border: 1px solid transparent;
		margin: 0;
		&:hover {
			border: 1px solid $color-gray-5;
		}
	}
}

.titleInput {
	background: none;
	outline: none;
	border: 1px solid transparent;
	width: 100%;
	appearance: none;
	@include type-h3;
	padding: 0 !important;
	margin: 0 !important;
	margin-top: -1px !important;
	margin-bottom: 1px !important;
	// padding-left: 1px !important;
	&:hover {
		border: 1px solid $color-gray-7;
	}
	&:focus {
		border: 1px solid $color-gray-2;
	}
}
