@import '~scss/variables.scss';

.hero {
	position: relative;
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($color-black, 0.48);
		z-index: 2;
	}
}

.content {
	max-width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 7rem 0;
	z-index: 3;
	@include tablet-up {
		max-width: 40%;
	}
	> h1,
	> .h1,
	> h2,
	> .h2,
	> h3,
	> .h3,
	> h4,
	> .h4,
	> h5,
	> .h5,
	> h6,
	> .h6 {
		&:first-child {
			@include desktop-up {
				margin-top: 0;
			}
		}
	}
	@include all-type-color($color-white);
	@include tablet-up {
		padding: 10rem 0;
	}
}
