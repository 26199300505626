@import 'src/scss/mixins/browsers.scss';

//Font families
$font-family-1dormakaba-bold: '1dormakaba-bold', serif;
$font-family-1dormakaba-semiBold: '1dormakaba-semibold', serif;
$font-family-1dormakaba-regular: '1dormakaba-regular', serif;
$font-family-1dormakaba-light: '1dormakaba-light', serif;

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semiBold: 600;
$font-weight-bold: 700;

// Colors
// $color-green-light: #64cc62;
$color-green-light: #60be7f;
$color-green-base: #328400;

$color-yellow-base: #ffd66d;

$color-blue-light: #d2ddf2;
$color-blue-base: #003594;
$color-blue-dark: #002972;
$color-blue-darker: #011b4a;

$color-whale-blue: #f1f4f9;

$color-red-light: #ff4869;
$color-red-base: #e4002b;
$color-red-dark: #c3002b;
$color-red-darker: #9b0324;

$color-gradient-green-light: linear-gradient(90deg, #9fe2b1 6.12%, #bdeaad 100%);
$color-gradient-green: linear-gradient(90deg, #43c365 6.12%, #84d660 100%);
$color-gradient-green-dark: linear-gradient(90deg, #39b259 6.12%, #58b72f 100%);
$color-gradient-green-darker: linear-gradient(90deg, #2d9b4a 6.12%, #49a521 100%);

// Neutrals
$color-black: #000000;
$color-gray-1: #404040;
$color-gray-2: #757575;
$color-gray-3: #949494; // Elephant gray
$color-gray-4: #cacaca;
$color-gray-5: #dedede;
$color-gray-6: #f2f2f2;
$color-gray-7: #fafafa;
$color-white: #ffffff;

$color-transparent: transparent;

$box-shadow-small: 0px 4px 8px rgba(0, 0, 0, 0.06);
$box-shadow-large: rgba(9, 30, 66, 0.13) 0px 4px 11px;

// Z-index
$z-index-focused-panel-trigger: 1;
$z-index-button-content: 1;
$z-index-sidebar: 50;
$z-index-message: 50;
$z-index-dropdown-content: 75;
$z-index-dropdown: 75;
$z-index-modal-backdrop: 80;
$z-index-tooltip: 100;
$z-index-menu-toggle: 120;
$z-index-skip-navigation: 125;

// Size
// These are commonly used sizes across the application to create consistency among spacing throughout the application. If you need a space other than these, consider whether the exception is necessary.
$size-2: 0.2rem;
$size-4: 0.4rem;
$size-8: 0.8rem;
$size-12: 1.2rem;
$size-14: 1.4rem;
$size-16: 1.6rem;
$size-18: 1.8rem;
$size-20: 2rem;
$size-24: 2.4rem;
$size-28: 2.8rem;
$size-32: 3.2rem;
$size-40: 4rem;
$size-48: 4.8rem;
$size-56: 5.6rem;

//Reusable sizes for specific use cases
$size-gutter: 1.6rem;
$size-column: 9.6rem;
$size-container-margin-mobile: 2rem;
$size-container-margin-desktop: 3rem;

// Breakpoint
$breakpoint-mobile-small: 320;
$breakpoint-mobile: 480;
$breakpoint-tablet: 768;
$breakpoint-desktop: 1024;
$breakpoint-desktop-large: 1200;
$breakpoint-desktop-larger: 1800;

// Container
$container-width: 132.8;

// Border-radius
$border-radius: 0.5rem;
$border-radius-large: 1rem;

// Typography
$type-060-font-size-desktop: 0.6rem;
$type-060-line-height-desktop: 1rem;
$type-060-font-size-mobile: 0.6rem;
$type-060-line-height-mobile: 1rem;

$type-070-font-size-desktop: 1rem;
$type-070-line-height-desktop: 1.6rem;
$type-070-font-size-mobile: 1rem;
$type-070-line-height-mobile: 1.6rem;

$type-080-font-size-desktop: 1.2rem;
$type-080-line-height-desktop: 2rem;
$type-080-font-size-mobile: 1.2rem;
$type-080-line-height-mobile: 2rem;

$type-100-font-size-desktop: 1.4rem;
$type-100-line-height-desktop: 2rem;
$type-100-font-size-mobile: 1.4rem;
$type-100-line-height-mobile: 2rem;

$type-200-font-size-desktop: 1.6rem;
$type-200-line-height-desktop: 2.4rem;
$type-200-font-size-mobile: 1.6rem;
$type-200-line-height-mobile: 2.4rem;

$type-300-font-size-desktop: 1.8rem;
$type-300-line-height-desktop: 2.6rem;
$type-300-font-size-mobile: 1.8rem;
$type-300-line-height-mobile: 2.6rem;

$type-400-font-size-desktop: 2.2rem;
$type-400-line-height-desktop: 3rem;
$type-400-font-size-mobile: 2rem;
$type-400-line-height-mobile: 2.8rem;

$type-500-font-size-desktop: 2.8rem;
$type-500-line-height-desktop: 4rem;
$type-500-font-size-mobile: 2.4rem;
$type-500-line-height-mobile: 3.2rem;

$type-600-font-size-desktop: 3.6rem;
$type-600-line-height-desktop: 4.5rem;
$type-600-font-size-mobile: 2.8rem;
$type-600-line-height-mobile: 4rem;

$type-700-font-size-desktop: 4rem;
$type-700-line-height-desktop: 4.8rem;
$type-700-font-size-mobile: 3.6rem;
$type-700-line-height-mobile: 4.6rem;

$type-800-font-size-desktop: 5.6rem;
$type-800-line-height-desktop: 7.2rem;
$type-800-font-size-mobile: 4rem;
$type-800-line-height-mobile: 4.8rem;

$type-900-font-size-desktop: 7rem;
$type-900-line-height-desktop: 8.5rem;
$type-900-font-size-mobile: 4.8rem;
$type-900-line-height-mobile: 6rem;

$type-block-title-font-size-desktop: 1.8rem;
$type-block-title-line-height-desktop: 2.4rem;
$type-block-title-font-size-mobile: 1.6rem;
$type-block-title-line-height-mobile: 2rem;

@import 'src/scss/mixins/accessibility';
@import 'src/scss/mixins/breakpoints';
@import 'src/scss/mixins/spacing';
@import 'src/scss/mixins/typography';
@import 'src/scss/mixins/svg';
@import 'src/scss/mixins/buttons.module';
@import 'src/scss/mixins/gridWidth';
