@import '~scss/variables.scss';
.width100percent {
	width: 100%;
}

.flexWrapNone {
	flex-wrap: nowrap;
}

// Print Overrides for Individual Quote Screen
.hideFromPrint {
	@media print {
		display: none !important;
	}
}
