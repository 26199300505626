@import '~scss/variables.scss';

.tableLight {
	.tableDom {
		width: 100%;
		overflow: auto;
		max-width: 100%;
		@include tablet-up {
			display: table;
		}
	}
	.tr {
		&:first-child {
			.th {
				padding-bottom: $size-8;
			}
		}
		@include tablet-up {
			display: table-row;
		}
	}
	.td,
	.th,
	.mobileTh {
		display: flex;
		align-items: center;
		@include tablet-up {
			display: table-cell;
		}
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.th {
		@include tablet-up {
			padding-right: 0.8rem;
			&:last-of-type {
				padding-right: 0;
			}
		}
		@include tablet-down {
			display: none;
		}
	}
	.mobileTh {
		@include tablet-up {
			display: none;
		}
	}
	.td {
		@include tablet-up {
			padding-right: 0.8rem;
			&:last-of-type {
				padding-right: 0;
			}
		}
		border-bottom: 1px solid $color-gray-5;
		padding-top: 2rem;
		padding-bottom: 2rem;
		color: $color-gray-1;
		@include tablet-down {
			border-bottom: none;
			padding-top: 0;
			padding-bottom: 0;
			&:first-of-type {
				padding-top: 2rem;
			}
			&:last-of-type {
				padding-bottom: 2rem;
				border-bottom: 1px solid $color-gray-5;
			}
		}
	}
	.tr:last-of-type .td {
		border-bottom: none;
		padding-bottom: 1.2rem;
	}
}

.headerItemInner {
	text-transform: uppercase;
	font-size: 1.2rem;
	line-height: 1.6rem;
	height: 100%;
	color: $color-gray-3;
	font-weight: $font-weight-bold;
}
