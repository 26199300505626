@import '~scss/variables.scss';

.section {
	width: 80%;
	@include margin-large-bottom;
	p {
		margin-bottom: 0;
	}
}

.stepHeader {
	@include margin-large-bottom;
}
.stepTitle {
	font-weight: $font-weight-bold;
	font-family: $font-family-1dormakaba-bold;
	margin-top: 0;
}
.stepDescription {
	@include type-500;
}

.stepQuestion {
	font-weight: $font-weight-bold;
	font-family: $font-family-1dormakaba-bold;
	margin-bottom: 1rem;
}

.openingValue {
	grid-column: span 3;
}

.required {
	color: $color-red-base;
}
