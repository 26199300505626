@import '~scss/variables.scss';

.questionStep {
	@include margin-large-bottom;
	p {
		margin-bottom: 0;
	}
}

.stepHeader {
	@include margin-small-bottom;
}
.stepTitle {
	font-weight: $font-weight-bold;
	font-family: $font-family-1dormakaba-bold;
	margin-top: 0;
}
.stepDescription {
	@include type-500;
}

.stepQuestion {
	h2 {
		font-weight: $font-weight-bold;
		font-family: $font-family-1dormakaba-bold;
	}
}

.labelDescription {
	@include type-400;
}

.openingValue {
	grid-column: span 3;
}

.openingValueSmall {
	grid-column: span 2;
}

.required {
	color: $color-red-base;
}

.inlineQuestionTitle {
	margin-top: $size-48;
	margin-bottom: $size-32;
}
