@import '~scss/variables.scss';

.flexContainer {
	display: flex;
}

.other-user {
	background: $color-gray-6;
	vertical-align: middle;
	position: relative;
	border-radius: $border-radius;
	min-height: 8rem;
	@include padding-small;
	@include margin-small;
}
.other-user.arrowLeft:after {
	content: ' ';
	position: absolute;

	left: -15px;
	top: 15px;

	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;

	border-right: 15px solid $color-gray-6;
	border-left: none;
}

.current-user {
	background: $color-gray-4;
	vertical-align: middle;
	position: relative;
	border-radius: $border-radius;
	min-height: 8rem;
	@include padding-small;
	@include margin-small;
}
.current-user.arrowLeft:after {
	content: ' ';
	position: absolute;

	left: -15px;
	top: 15px;

	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;

	border-right: 15px solid $color-gray-4;
	border-left: none;
}

.nameText {
	color: $color-blue-base;
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
	font-size: medium;
	word-wrap: break-word;
}

.nameAndDateTimeContainer {
	@include padding-small;
	padding-top: 0 !important;
	@include margin-small;
}
