@import '~scss/variables.scss';

.inputAutoSuggestWrapper {
	position: relative;
	display: table;
	width: 100%;
	&.positionRight {
		.dropdownContentArea {
			left: initial;
			right: 0;
		}
	}
}

.autoSuggestionContentArea {
	background: $color-white;
	position: absolute;
	bottom: 0;
	transform: translateY(100%) translateY(#{$size-4});
	left: 0;
	border-radius: $border-radius;
	width: 28.4rem;
	z-index: $z-index-dropdown-content;
	box-shadow: $box-shadow-large;
	max-height: 30rem;
	overflow-y: scroll;
}
