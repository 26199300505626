@import '~scss/variables.scss';

.createOpeningNavigation {
	background: $color-whale-blue;
}

.createOpeningNavigationContent {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include padding-medium;

	span {
		font-family: $font-family-1dormakaba-semiBold;
		font-weight: $font-weight-semiBold;
		span {
			color: $color-red-base;
		}
	}
}

.createOpeningNavigationControls {
	.createOpeningButton {
		display: inline-block;

		@include margin-medium-right;
		&:last-child {
			margin-right: 0;
		}
	}
}
