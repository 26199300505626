@import '~scss/variables.scss';

.orderStatus {
	display: flex;
	align-items: center;
	@include margin-medium-vertical;
	padding: $size-32 $size-14 $size-32 $size-14;
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
}

.centerFlex {
	display: flex;
	align-items: center;
}

.textBold {
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
}

.marginRight {
	margin-right: $size-12;
}

.NotSubmitted {
	background: $color-yellow-base;
	vertical-align: middle;
}

.Submitted {
	background: $color-blue-light;
	vertical-align: middle;
}

.InReview {
	background: $color-blue-base;
	color: $color-white;
	vertical-align: middle;
}

.OrderPlaced {
	background: $color-green-light;
	vertical-align: middle;
}
