@import '~scss/variables.scss';

.noImage {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: $color-white;
	span {
		text-transform: uppercase;
		text-align: center;
	}
}

strong {
	display: block;
	font-family: $font-family-1dormakaba-semiBold;
	font-weight: $font-weight-semiBold;
}

.small {
	.cameraIcon {
		margin-bottom: $size-2;
	}
	span {
		@include type-060;
	}
	strong {
		@include type-200;
		margin-bottom: -0.6rem;
	}
}

.medium {
	.cameraIcon {
		margin-bottom: $size-4;
	}
	span {
		@include type-070;
	}
	strong {
		@include type-400;
		margin-bottom: -0.6rem;
	}
}

.large {
	.cameraIcon {
		margin-bottom: $size-8;
	}
	span {
		@include type-080;
	}
	strong {
		@include type-500;
		margin-bottom: -0.6rem;
	}
}

.largest {
	.cameraIcon {
		margin-bottom: $size-12;
	}
	span {
		@include type-100;
	}
	strong {
		@include type-600;
		margin-bottom: -0.6rem;
	}
}
