@import '~scss/variables.scss';

.footer {
	background: $color-gray-1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: $size-16 $size-24;
	@media print {
		display: none !important;
	}
}

.copy {
	color: $color-white;
	margin: 0;
}

.footerMenu {
	margin-top: 0;
	li {
		display: inline-block;
		margin-right: $size-24;
		a {
			color: $color-white;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
