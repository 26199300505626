@import '~scss/variables.scss';

.inputContainer {
	position: relative;
	border: 0.1rem solid $color-gray-4;
}

.input {
	display: block;
	position: relative;
	width: 100%;
	margin: 0;
	padding: 1rem 2rem $size-12 2rem;
	border: 0;

	resize: vertical;

	&::placeholder {
		color: $color-gray-3;
	}
}

.hasIcon {
	padding-right: $size-40;
}

.icon {
	position: absolute;
	top: 50%;
	right: 2rem;
	transform: translateY(-50%);
}

.error {
	border: 0.2rem solid $color-red-base;
}
