@import '~scss/variables.scss';

.input {
	position: absolute !important;
	height: 0.1rem;
	width: 0.1rem;
	overflow: hidden;
	transform: translateY(1.2rem);
	clip: rect(0.1rem 0.1rem 0.1rem 0.1rem); /* IE6, IE7 */
	clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
	cursor: pointer;
}

.label {
	transition: 0.3s all;
	position: relative;
	display: flex;
	cursor: pointer;
	background: $color-white;
	border: 3px solid $color-gray-4;
	padding: $size-24;
	@include type-300;
	font-family: $font-family-1dormakaba-semiBold;
	font-weight: $font-weight-semiBold;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 14rem;
	&:hover {
		border: 3px solid $color-gray-2;
	}
}

.labelHasIcon {
	min-height: 22rem;
}

.input:checked + .label {
	border: 3px solid $color-blue-base;
}
/*Adding focus styles on the outer-box of the fake checkbox*/

:global {
	.user-is-tabbing {
		:local {
			.input:focus + .label {
				outline: $color-blue-light auto 0.4rem;
			}
		}
	}
}

/*Adding style for disabled checkbox*/
.input:disabled + .label {
	color: $color-gray-6;
}

.input:disabled + .label::before {
	background: $color-gray-6;
	border: 0.1rem solid $color-gray-6;
}

.error {
	color: $color-red-base;
}

.label {
	user-select: none;
}

.radio {
	position: relative;
}

.tooltipIcon {
	position: absolute;
	top: $size-20;
	right: $size-24;
}
