@import '~scss/variables.scss';

.dropdownSupport {
	width: auto;
	border-right: 0.1rem solid $color-gray-5;
	@include tablet-up {
		display: table;
	}
	button {
		@include type-100;
		@include tablet-up {
			@include type-200;
		}
	}
}
.dropdownCaret {
	@include all-svg-elements {
		stroke-width: 2px !important;
	}
}

.dropdownSupportButton {
	color: $color-gray-1;
	font-weight: $font-weight-regular;
}

.profileContent {
	display: flex;
	@include padding-small;
	align-items: center;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-bottom: 0.1rem solid $color-gray-5;
	}
}

.name {
	margin-top: 0;
	margin-bottom: $size-4;
	transform: translateY(2px);
	@include type-200;
	font-family: $font-family-1dormakaba-regular;
	font-weight: $font-weight-regular;
}

.username {
	margin: 0;
	font-size: 1rem;
	line-height: $size-12;
	transform: translateY(0.5px);
	text-overflow: ellipsis;
	max-width: 18rem;
	overflow: hidden;
}

.buttonLabelProfileIcon {
	order: initial;
	height: $size-48;
	width: $size-48;
	margin-right: 1.5rem;
}
