@import '~scss/variables.scss';

.boldText {
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
}

.italicText {
	font-style: italic;
	display: inline;
}

.inlineLink {
	display: inline;
}

.appframe {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@include desktop-up {
		flex-direction: row;
	}
}

.inputLabel {
	transform: translateY(-1px);
	@include type-080;
	margin-bottom: $size-12;
	text-transform: uppercase;
	color: $color-gray-2;
	font-weight: $font-weight-bold;
	display: block;
}

.main {
	// flex-grow: 1;
	// This is to prevent flex issues
	width: 100%;
	@include desktop-up {
		width: 73%;
	}
}

.flexContainer {
	display: flex;
	align-items: center;
}

.largeFlex {
	width: 100%;
}

.mediumFlex {
	width: 20rem;
}

.smallFlex {
	width: 12rem;
}

.flexItem {
	@include padding-small-left();
}

.alignFlexStart {
	align-items: flex-start;
}

.justifySpaceBetween {
	justify-content: space-between;
	gap: $size-20;
}

.printWrapper {
	@include margin-medium-top;
}

.stacked {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.productAttribute {
	@include margin-small-bottom();
}

.marginLeftAuto {
	margin-left: auto;
}


.printWrapper,
.printButton {
	display: block;
	width: 100%;
	// margin-left: auto;
}

.rightSidePanel {
	width: 100%;
	padding-top: $size-24;
	@include desktop-up {
		width: 24%;
	}
}

.rightSidePanelControls {
	margin-top: 1.1rem;
	gap: $size-12;
	justify-content: flex-end;
}

.dangerLink {
	color: $color-red-base;
}

.orderTitle {
	@include margin-small-bottom;
}

.orderDisclaimer {
	margin-top: 2.2rem;
	@include margin-medium-bottom;
}

.orderControl {
	border-color: $color-gray-4;
	background-color: $color-gray-4;
	margin-top: -0.1rem;
	text-align: center;
}

.saveOpening {
	@include margin-small-top;
}

.viewPricingLabel {
	margin-right: $size-12;
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
}

.width100percent {
	width: 100%;
}

.submit {
	display: block;
	width: 100%;
}

// Print Overrides for Individual Quote Screen
.hideFromPrint {
	@media print {
		display: none !important;
	}
}

// Below CSS copied from the Panel Component
.panelTitle {
	@include type-block-title;
	@include margin-small-bottom;
}

.addProductToOrder {
	margin-top: -0.1rem;
	background: $color-whale-blue;
}

.addProductToOrderButton {
	display: block;
	width: 100%;
}

.subText {
	color: $color-gray-3;
	font-size: small;
	word-wrap: break-word;
}
