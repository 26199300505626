@import '~scss/variables.scss';

.inputFrame {
	display: flex;
	flex-direction: column;
	border: none;
	padding: 0;
	margin-bottom: $size-12;
	margin-left: 0;
	margin-right: 0;
	&:last-child {
		margin-bottom: 0;
	}
}

.inputLabel {
	transform: translateY(-1px);
	padding-left: 0;
	@include type-080;
	margin-bottom: $size-12;
	text-transform: uppercase;
	color: $color-gray-2;
	font-weight: $font-weight-bold;
	&.labelError {
		color: $color-red-base;
	}
}

.tooltipContainer {
	white-space: nowrap;
	.label {
		white-space: normal;
		display: inline;
	}
}

.tooltip {
	margin-top: -0.2rem;
	display: inline;
	margin-bottom: -0.5rem;
}

.labelLeft {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	.inputFrameControls {
		flex-direction: column;
		padding-left: 1.5rem;
	}
}

.isRequired:after {
	content: '*';
	margin-left: $size-4;
	color: $color-red-base;
	font-size: 1em;
}
