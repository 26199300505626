@import '~scss/variables.scss';

.selectContainer {
	position: relative;
}

.select {
	width: 100%;
	border: 0.1rem solid $color-gray-4;
	margin: 0;
	padding: 1rem 5.5rem 1rem 2rem;
	box-shadow: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: $color-white;
	&::-ms-expand {
		display: none;
	}
}

.icon {
	position: absolute;
	top: 50%;
	right: 2rem;
	transform: translateY(-50%);
	pointer-events: none;
}

.error {
	border: 0.2rem solid $color-red-base;
}
