@import '~scss/variables.scss';

.displayFlex {
	flex-direction: row !important;
	display: flex !important;
	align-items: center;
	gap: 10px;
}

.customerServiceUserFilter {
	max-width: 32rem;
}

.smallColumn {
	width: 12rem;
}
