@import '~scss/variables.scss';
.dropdownList {
	padding-top: $size-16;
	li {
		list-style: none;
		padding: 0;
		margin: 0;
		a,
		button {
			display: block;
			text-decoration: none;
			font-size: $size-14;
			padding-left: $size-24;
			padding-top: $size-8;
			padding-bottom: $size-8;
			width: 100%;
			text-align: left;
			&:hover {
				background: $color-gray-7;
				text-decoration: none;
			}
		}
		transform: translateY(0);
	}
}
.active {
	text-decoration: underline;
}
.buttonContent {
	justify-content: flex-start !important;
}
.sectionSearchResults {
	h1 {
		@include padding-medium-vertical;
		@include desktop-up {
			@include padding-large-vertical;
		}
	}
}
.searchContainer {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	@include desktop-up {
		justify-content: space-between;
		flex-direction: row;
	}
}
.linksContainer {
	background: $color-whale-blue;
	width: 100%;
	@include margin-medium-bottom;
	@include desktop-up {
		width: 29%;
		@include padding-large-bottom;
	}
	@include desktop-large-up {
		width: 24%;
	}
}
.mobileFilterTrigger {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	text-transform: capitalize;
	font-family: $font-family-1dormakaba-semiBold;
	font-weight: $font-weight-semiBold;
	@include desktop-up {
		display: none;
	}
}
.linksHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.linksHeaderText {
	padding-top: $size-24;
	padding-left: $size-24;
	text-transform: capitalize !important;
	margin-top: 0;
}
.resultsContainer {
	width: 100%;
	@include desktop-up {
		width: 69%;
	}
	@include desktop-large-up {
		width: 74%;
	}
}
