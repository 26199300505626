@import '~scss/variables.scss';

.appframe {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.content {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.skipNavigation {
	position: absolute;
	top: 0;
	left: 0;
	z-index: $z-index-skip-navigation;
	transform: translateY(-100%);
	transition: all 0.3s ease;
	background: $color-white;
	padding: 1rem;

	&:focus,
	&:active {
		transform: translateY(0);
	}
}
