@import '~scss/variables.scss';

.productCard {
	border: 0.1rem solid $color-gray-5;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include padding-medium;
}
.productCardLeft {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	@include mobile-up {
		flex-direction: row;
		width: auto;
	}
}

ul {
	@include margin-small-top;
	padding-left: 1.5rem;
	li {
		list-style: disc;
	}
}

.productDetails {
	max-width: 44rem;
	h2 {
		margin-top: 0;
	}
}

small {
	font-style: italic;
	@include type-080;
}

h3 small {
	font-style: normal;
	font-weight: $font-weight-regular;
	font-family: $font-family-1dormakaba-regular;
	@include type-080;
}

.productImageContainer {
	position: relative;
	width: 100%;
	min-height: 20rem;
	@include mobile-up {
		width: 16rem;
		@include margin-large-right;
	}
	@include tablet-up {
		width: 20rem;
		min-height: 20rem;
	}
}

.productImage {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.imageComingSoon {
	border: 0.1rem solid $color-gray-5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mobileProductLink {
	display: block;
	@include margin-small-top;
	@include desktop-up {
		max-width: 20rem;
	}
	@include desktop-large-up {
		display: none;
	}
}

.productLink {
	display: none;
	@include desktop-large-up {
		display: block;
	}
}
