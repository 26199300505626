@import '~scss/variables.scss';

@mixin on-button-control-hover {
	:global {
		.button:hover {
			:local {
				.control {
					@content;
				}
			}
		}
	}
}

.icon {
	@include all-svg-elements {
		stroke-width: 0.15rem;
	}
	width: 5rem;
	max-height: 6rem;
}
