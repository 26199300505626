@import '~scss/variables.scss';

.boldText {
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
}

.italicText {
	font-style: italic;
}
