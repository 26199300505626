@import '~scss/variables.scss';

.status {
	font-family: $font-family-1dormakaba-semiBold;
	font-weight: $font-weight-semiBold;
}

.redText {
	color: $color-red-base;
}

.greenText {
	color: $color-green-base;
}

.min {
	min-width: fit-content;
}

.smallColumn {
	width: 18rem;
}

.extraSmallColumn {
	width: 10rem;
}

.displayFlex {
	flex-direction: row !important;
	display: flex !important;
	align-items: center;
	gap: 10px;
}

.boldTextMarginTopZero {
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
	margin-top: 0;
}

.marginBottomZero {
	margin-bottom: 0;
}
