@import '~scss/variables.scss';

.label {
	display: flex;
	align-items: center;
	width: fit-content;
	position: relative;

	&:hover {
		cursor: pointer;

		.indicator:after {
			opacity: 1;
			transition: opacity 0.2s;
		}
	}
}
// Add focus styles for autoRenew input
@include tabbing {
	input[name='autoRenew']:focus + .label::before {
		content: '';
		display: block;
		position: absolute;
		inset: -0.5rem;
		border: solid 0.5rem $color-green-light;
		border-radius: $border-radius;
		@include tablet-up {
			border-radius: $border-radius-large;
		}
	}
}

.track {
	width: $size-48;
	height: $size-24;
	border-radius: $size-12;
	background-color: $color-gray-4;
	position: relative;
	transition: background-color 0.2s;

	&.checked {
		background-color: $color-blue-base;
		transition: background-color 0.2s;
	}
}

.indicator {
	height: $size-24;
	width: $size-24;
	transition: transform 0.3s;

	&.checked {
		transform: translateX(calc($size-48 - $size-24));
		transition: transform 0.2s;
	}

	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0.3rem;
		left: 0.3rem;
		border-radius: $size-12;
		background-color: $color-white;
		width: 1.8rem;
		height: 1.8rem;
		filter: drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.2));
	}

	&:after {
		filter: drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.25));
		opacity: 0;
		transition: opacity 0.2s;
	}
}

.label:active {
	.indicator {
		transform: translateX($size-2);

		&.checked {
			transform: translateX(calc($size-48 - $size-24 - $size-2));
		}
	}
}

.labelText {
	margin-left: $size-8;
	padding-top: $size-2;
	flex: 1;

	@include type-100;

	& i {
		@include type-080;
	}
}
