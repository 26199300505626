@import '~scss/variables';

@mixin column($amount) {
	margin-right: #{$size-gutter};
	@include gridWidth($amount);
	&:nth-child(#{$amount}n) {
		margin-right: 0;
	}
}

.gridItem {
	width: 100%;
	margin-top: 0;
	margin-bottom: $size-16;

	&.border {
		border: 1px solid $color-gray-5;
	}

	@at-root button#{&} {
		background-color: inherit;
		border: none;
		cursor: pointer;
		padding: 0;
		&:hover {
			color: $color-blue-light;
		}
	}

	@at-root a#{&} {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
	}

	@include tablet-up {
		@include desktop-down {
			@include column(2);
		}
	}

	@include desktop-up {
		@include desktop-large-down {
			@include column(3);
		}
	}
}

:global {
	@include tablet-up {
		.two {
			:local {
				.gridItem {
					@include column(2);
					&.hasDesktopSpace {
						&:nth-child(n + 3) {
							margin-top: $size-12;
						}
					}
				}
			}
		}
	}

	@include tablet-up {
		.three {
			:local {
				.gridItem {
					@include column(3);
				}
			}
		}
	}

	@include desktop-up {
		.four {
			:local {
				.gridItem {
					@include column(4);
				}
			}
		}
	}

	@include desktop-large-up {
		.six {
			:local {
				.gridItem {
					@include column(6);
				}
			}
		}
	}
}
