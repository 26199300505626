@import '~scss/variables.scss';

.input {
	position: absolute !important;
	height: 0.1rem;
	width: 0.1rem;
	overflow: hidden;
	transform: translateY(1.2rem);
	clip: rect(0.1rem 0.1rem 0.1rem 0.1rem); /* IE6, IE7 */
	clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
	cursor: pointer;
}

.label {
	position: relative;
	display: inline-block;

	padding-left: $size-32;
	transform: translateY(-1px);
	padding-top: $size-4;
	padding-bottom: $size-4;
	cursor: pointer;
}

.label::before,
.label::after {
	position: absolute;
	content: '';

	/*Needed for the line-height to take effect*/
	display: inline-block;
}

/*Outer box of the fake checkbox*/
.label::before {
	height: 2.4rem;
	width: 2.4rem;
	border: 0.1rem solid $color-gray-4;
	border-radius: 100%;
	left: 0;
	top: 0.3rem;
	background-repeat: no-repeat;
	background-position: center;
}

.isLabelHidden::before {
	top: -0.8rem;
}

.input:checked + .label::after {
	height: 1.8rem;
	width: 1.8rem;
	display: block;
	background-color: $color-blue-base;
	top: 0.6rem;
	left: 0.3rem;
	border-radius: 100%;
}

/*Adding focus styles on the outer-box of the fake checkbox*/

:global {
	.user-is-tabbing {
		:local {
			.input:focus + .label::before {
				outline: $color-blue-light auto 0.4rem;
			}
		}
	}
}

/*Adding style for disabled checkbox*/
.input:disabled + .label {
	color: $color-gray-6;
}

.input:disabled + .label::before {
	background: $color-gray-6;
	border: 0.1rem solid $color-gray-6;
}

.error {
	color: $color-red-base;
}

.label {
	user-select: none;
}

.isRequired:after {
	color: $color-red-base;
	font-size: 1em;
	margin-left: $size-4;
	content: '*';
}
