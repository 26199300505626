@import '~scss/variables.scss';

.searchBox {
	background: $color-white;
	@include padding-medium-bottom;
	@include desktop-up {
		@include padding-large-bottom;
	}
}

.sectionSearchResults {
	@include padding-small-top;
	h1 {
		@include padding-medium-bottom;
		@include desktop-up {
			@include padding-large-bottom;
		}
	}
}

.searchContainer {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	@include desktop-up {
		justify-content: space-between;
		flex-direction: row;
	}
}

.filterContainer {
	background: $color-whale-blue;
	padding: $size-24;
	width: 100%;
	@include margin-medium-bottom;
	@include desktop-up {
		width: 29%;
		@include padding-large-bottom;
	}
	@include desktop-large-up {
		width: 24%;
	}
}

.mobileFilterTrigger {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	text-transform: capitalize;
	font-family: $font-family-1dormakaba-semiBold;
	font-weight: $font-weight-semiBold;
	@include desktop-up {
		display: none;
	}
}

.filterContent {
	display: none;
	padding-top: $size-24;
	&.isVisible {
		display: block;
	}
}

.filterHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include margin-medium-bottom;
	@include margin-small-top;
}

.filterHeaderText {
	text-transform: capitalize !important;
	margin-top: 0;
}

.resultsContainer {
	width: 100%;
	@include desktop-up {
		width: 69%;
	}
	@include desktop-large-up {
		width: 74%;
	}
}
