@import '~scss/variables.scss';

.flexContainer {
	display: flex;
	align-items: center;
}

.justifySpaceBetween {
	justify-content: space-between;
}

.orderTotal {
	background-color: $color-gray-4;
	border-color: $color-gray-4;
	margin-top: -0.1rem;
}

.black {
	color: $color-black;
}

.orderDiscountText {
	font-size: $size-12;
	line-height: $size-16;
}

// Print Overrides for Individual Quote Screen
.hideFromPrint {
	@media print {
		display: none !important;
	}
}
