@import '~scss/variables.scss';

.boldText {
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
}

.flex {
	display: flex;
}

.flexContainer {
	display: flex;
	align-items: center;
}

.paddingZero {
	padding: 0;
}

.columnPadding {
	padding: $size-14 $size-14;
}

.smallColumn {
	width: 10rem;
	padding: $size-14 0.8rem;
}

.mediumColumn {
	width: 16rem;
	padding: $size-14 0.8rem;
}

.firstColumn {
	width: 24rem;
	padding: $size-14 0.8rem;
	@include padding-medium-left;
}

.productImageContainer {
	padding-right: $size-12;
}

.subText {
	color: $color-gray-3;
	font-size: small;
	word-wrap: break-word;
}

.wordWrapBreak {
	word-wrap: break-word;
}

.nameText {
	color: $color-blue-base;
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
	font-size: medium;
	word-wrap: break-word;
}

// Below CSS copied from the Panel Component
.panelTitle {
	@include margin-medium-bottom;
	h2 {
		@include type-block-title;
		margin: 0;
	}
}

.panelDescription {
	margin-top: 0;
	@include margin-small-bottom;
}

.panelCount {
	color: $color-gray-3;
}

.panelCount {
	@include margin-small-left;
}

.lineItemDetails {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
	}
}

.lineItemDetailKey {
	text-transform: capitalize;
}

.lineItemsPanel {
	@include padding-medium-vertical;
}

.lineItemsPanelTitleContainer {
	@include padding-medium-left;
}

// Print Overrides for Individual Quote Screen
.hideFromPrint {
	@media print {
		display: none !important;
	}
}

.hideScreen {
	@media screen {
		display: none !important;
	}
}
