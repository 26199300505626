@import '~scss/variables.scss';

@mixin breakpoint {
	.mobile-up & {
		@include mobile-up {
			@content;
		}
	}
	.desktop-up & {
		@include desktop-up {
			@content;
		}
	}
	.desktop-large-up & {
		@include desktop-large-up {
			@content;
		}
	}
	.desktop-larger-up & {
		@include desktop-larger-up {
			@content;
		}
	}
}

.splitContent {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-direction: column;
	@include breakpoint {
		flex-direction: row;
	}
	&.isMobileOrientationReversed {
		flex-direction: column-reverse;
		@include breakpoint {
			flex-direction: row;
		}
	}
}

.contentArea {
	position: relative;
	margin-bottom: $size-gutter;
	width: 100%;
	&:last-child {
		margin-bottom: 0;
	}
	@include breakpoint {
		margin-bottom: 0;
	}
	.isMobileOrientationReversed & {
		margin-bottom: 0;
		&:last-child {
			margin-bottom: $size-gutter;
		}
		@include breakpoint {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&.hasSplitMargin {
		@include breakpoint {
			margin-right: #{$size-gutter};
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.twoThird_oneThird {
	@include desktop-up {
		.contentAreaLeft {
			flex: 1;
		}
	}
	.contentAreaRight {
		@include breakpoint {
			@include gridWidth(3);
		}
	}
}
.oneHalf_oneHalf {
	.contentAreaRight,
	.contentAreaLeft {
		@include breakpoint {
			width: 50%;
			flex: 1;
		}
	}
}

.oneFourth_threeFourth {
	.contentAreaLeft {
		@include breakpoint {
			@include gridWidth(4);
		}
	}
	.contentAreaRight {
		flex: 1;
	}
}

.threeFourth_oneFourth {
	.contentAreaRight {
		@include breakpoint {
			@include gridWidth(4);
		}
	}
	.contentAreaLeft {
		flex: 1;
	}
}
