@import '~scss/variables.scss';

.refNumberManager {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	ul {
		width: 35%;
		padding-top: 1rem;
		li {
			padding: 0.3rem 0;
		}
	}
	.textareaControl {
		width: 65%;
	}
	.textarea {
		line-height: 2.97rem;
		min-height: 46rem;
	}
}

.boldTextMarginTopBottom {
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
	margin-bottom: 1.5rem;
}
