@import '~scss/variables';

@include button;

.hasChildren {
	.iconLeft {
		margin-right: 0.4rem;
	}
	.iconRight {
		margin-left: 0.4rem;
	}
}

.link {
	padding: 0;
	width: fit-content;
	height: fit-content;
	background-color: $color-transparent;
	color: $color-blue-base;
	text-align: left;
	transition: none;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.spinner {
	margin-right: $size-8;
	margin-top: -0.2rem;
	margin-bottom: -0.2rem;
	.link & {
		bottom: -0.4rem;
		position: relative;
	}
	.linkSmall & {
		bottom: -0.5rem;
		position: relative;
	}
}

// Tooltip
.tooltip {
	background-color: $color-gray-1;
	color: $color-white;
	font-size: 1.2rem;
	padding: 0.4rem 1.2rem;
	border-radius: 0.3rem;
	z-index: $z-index-tooltip;
	max-width: 18rem;
	svg {
		path {
			fill: $color-blue-base;
		}
	}
}

// Override states for loading
.isLoading {
	&:disabled,
	&:disabled:hover {
		color: $color-white;
	}
	&.secondary {
		&:disabled,
		&:disabled:hover {
			color: $color-black;
		}
	}
}
