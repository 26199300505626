@import '~scss/variables.scss';

.detailHeadline {
	margin-top: $size-12;
	@include type-080;
	font-family: $font-family-1dormakaba-bold;
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	color: $color-gray-2;
	transform: translateY(-0.1rem);
	margin-bottom: $size-4;
}

.detailContent {
	color: $color-gray-3;
	transform: translateY(-0.1rem);
}

.form {
	padding: $size-16 0;
}

.titleIcon {
	position: relative;
	margin-bottom: -0.5rem;
	margin-left: 0.4rem;
	margin-top: -0.4rem;
}
