@import '~scss/variables.scss';

.tableMetaActions {
	@include desktop-down {
		width: 100%;
	}
	@include desktop-up {
		display: flex;
		align-items: center;
	}
}

.action.action {
	margin-bottom: 1rem;
	&:last-child {
		margin-bottom: 0;
	}
	@include desktop-down {
		width: 100%;
	}
	@include desktop-up {
		margin-bottom: 0;
		margin-left: 1rem;
		&:last-child {
			margin-bottom: 0;
			margin-left: 1rem;
		}
		&:first-child {
			margin-left: 0;
		}
	}
}
