@import '~scss/variables.scss';

.panel {
	background-color: $color-white;
	border: 0.1rem solid $color-gray-5;
	width: 100%;
	& > ol:last-child {
		margin-bottom: 0;
	}
}

.hasPadding {
	@include padding-medium;
}

.titleWithTooltip {
	white-space: nowrap;
	.title {
		white-space: normal;
	}
	& > * {
		display: inline;
	}
}
.title {
	@include type-block-title;
	margin-bottom: 0;
	@include tablet-up {
		margin-top: -0.4rem;
		margin-bottom: -0.8rem;
	}
}

.description {
	margin: $size-8 0;
	margin-bottom: 0;
	margin-top: $size-16;
	color: $color-gray-3;
}

.doesPanelWrapTable {
	@include tablet-down {
		padding: 0;
		margin-left: -$size-container-margin-mobile;
		margin-right: -$size-container-margin-mobile;
		// This is written with the "1 *" to workaround a bug in sass that removes parentheses
		width: calc(100vw - (1 * (100vw - 100% - 4rem)));
	}
}

.topArea {
	margin-bottom: $size-8;
}
.headingArea {
	.contentTopRight {
		margin-top: $size-12;
	}
	@include tablet-up {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		.contentTopRight {
			margin-left: $size-16;
			margin-top: -$size-4;
		}
	}
}

.hasSpaceBelowTopArea {
	margin-bottom: $size-32;
}

.toolTipIcon {
	display: inline;
	position: relative;
	top: 0.3rem;
	margin-left: 0.2rem;
}
