@import '~scss/variables.scss';

.boldText {
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
}

.flex {
	display: flex;
}

.flexContainer {
	display: flex;
	align-items: center;
}

.paddingZero {
	padding: 0;
}

.columnPadding {
	padding: $size-14 $size-14;
}

.smallColumn {
	width: 10rem;
	padding: $size-14 0.8rem;
}

.mediumColumn {
	width: 16rem;
	padding: $size-14 0.8rem;
}

.firstColumn {
	width: 24rem;
	padding: $size-14 0.8rem;
	@include padding-medium-left;
}

.productImageContainer {
	margin-right: $size-12;
	position: relative;
	min-height: 2.4rem;
	min-width: 2.4rem;
	@include tablet-up {
		min-width: 5rem;
		min-height: 5rem;
	}
}

.productImage {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.imageComingSoon {
	border: 0.1rem solid $color-gray-5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.subText {
	color: $color-gray-3;
	font-size: small;
	word-wrap: break-word;
}

.wordWrapBreak {
	word-wrap: break-word;
}

.nameText {
	color: $color-blue-base;
	font-family: $font-family-1dormakaba-bold;
	font-weight: $font-weight-bold;
	font-size: medium;
	word-wrap: break-word;
}

// Below CSS copied from the Panel Component
.panelTitle {
	@include padding-medium-left;
	@include margin-medium-bottom;
	h2 {
		@include type-block-title;
		margin: 0;
	}
}

.panelCount {
	color: $color-gray-3;
}

.panelCount {
	@include margin-small-left;
}

.lineItemDetails {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
	}
}

.lineItemDetailKey {
	text-transform: capitalize;
}

.lineItemsPanel {
	@include padding-medium-vertical;
}

// Print Overrides for Individual Quote Screen
.hideFromPrint {
	@media print {
		display: none !important;
	}
}

.hideScreen {
	@media screen {
		display: none !important;
	}
}

.tagCommentInput {
	&::placeholder {
		font-size: 1.4rem;
	}
}
