@import '~scss/variables';

.gridContainer {
	@include tablet-up {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
}
